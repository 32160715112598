import { SEPARATOR } from '../constants';

export * from './api-error-status';

export const enum BaseLanguageType {
  EN = 'en',
  ZH_CN = 'zh-CN',
  ZH_TW = 'zh-TW',
}

export enum PaymentStatus {
  AUTHORIZED = 'Authorized',
  CANCELED = 'Canceled',
  CAPTURED = 'Captured',
  DELINED = 'Declined',
  PENDING = 'Pending',
  SYSTEM_ERROR = 'System Error',
  VOIDED = 'Voided',
  WEB3_ERROR = 'Web3 Error',
}

export enum BalanceType {
  DEPOSIT = 'Deposit',
  FREEZE = 'Freeze',
  MINT = 'Mint',
  RECEIVE = 'Receive',
  ROYALTY = 'Royalty',
  SERVICE_FEE = 'Service Fee',
  WITHDRAW = 'Withdraw',
}

export const enum ThemeType {
  DARK = 'is_dark',
  LIGHT = 'light',
}

/**
 * Old design? Return the raw database launchpad status, it should have a lot, but frontned
 * currently only check 'PUBLISH'
 */
export const enum LaunchpadRawStatus {
  PUBLISH = 'PUBLISH',
}

/**
 *  Backend will calculate all phase's start + end time and return the final status
 * - If all phases are upcoming, the status will be UPCOMING
 * - If one of the phase is live, the status will be LIVE
 * - If all phases are ended, the status will be ENDED
 */
export const enum LaunchpadStatus {
  ENDED = 'ENDED',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
}

export const enum ContractType {
  COLLECTION_CONTRACT_FACTORY = 'COLLECTION_CONTRACT_FACTORY',
  COLLECTION_SIGN_CONTRACT_INFO = 'COLLECTION_SIGN_CONTRACT_INFO',
  MARKETPLACE_721 = 'MARKETPLACE_721',
  MARKETPLACE_TREASURY_WALLET_CONTRACT = 'MARKETPLACE_TREASURY_WALLET_CONTRACT',
}

export const enum CollectionStatus {
  BLOCKED = 'BLOCKED',
  HIDDEN = 'HIDDEN',
  LISTING = 'LISTING',
  PENDING = 'PENDING',
}

export const enum TokenType {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
  SOULBOUND = 'SOULBOUND',
}

export const enum PaymentEventType {
  BID_NFT = 'BID_NFT',
  BUY_NFT = 'BUY_NFT',
  DEPLOY_COLLECTION = 'DEPLOY_COLLECTION',
  MINT_NFT = 'MINT_NFT',
}

export const enum NftEventType {
  // Display only
  CODE_LIST = 'CODE_LIST',
  // Force to plus one
  COUNT = 'COUNT',
  // Display only
  DISPLAY = 'DISPLAY',
  // Setter, allow to set any value
  POINTS = 'POINTS',
  // Call /event/nft/owner-qrcode/check will minus 1
  SYNC_QR_CODE_COUNT = 'SYNC_QR_CODE_COUNT',
}

export const enum PaymentGatewayType {
  CREDIT_CARD = 'CREDIT_CARD',
  CRYPTOCURRENCY = 'CRYPTOCURRENCY',
  INTEGRATION_PAYMENT = 'INTEGRATION_PAYMENT',
}

export const enum PaymentGatewayName {
  CHECKOUT = 'CHECKOUT',
  GKASH = 'GKASH',
  METAMASK = 'METAMASK',
  PAYDOLLAR = 'PAYDOLLAR',
  STRIPE = 'STRIPE',
}

export const enum KycStatusEnum {
  APPROVE = 'APPROVE',
  PENDING = 'PENDING',
}

export const enum AssetType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  MODEL = 'MODEL',
  VIDEO = 'VIDEO',
}

export const enum OrderType {
  BID = 'BID',
  SALE = 'SALE',
}

export const enum EventType {
  BID = 'BID',
  BID_ACCEPT = 'BID_ACCEPT',
  CANCEL = 'CANCEL',
  LIST = 'LIST',
  MINTED = 'MINTED',
  SALE = 'SALE',
  TRANSFER = 'TRANSFER',
}

export const enum ListingType {
  BID = 'bid',
  BUY = 'buy',
}

export const enum AttributeDisplayType {
  COUNT_DOWN = 'count_down',
  DATE_TIME = 'date_time',
  TEXT = 'text',
  URL = 'url',
}

export const enum FlexibleMintSubmitStatus {
  APPROVE = 'APPROVE',
  CANCEL = 'CANCEL',
  DONE = 'DONE',
  FAILED = 'FAILED', // Web3 failed
  MINTING = 'MINTING', // Web3 processing
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING', // Backend processing
  REJECT = 'REJECT',
}

export const enum WalletProviderType {
  CUSTONOMY = 'CUSTONOMY', // External wallet provider
  NFTIV = 'NFTIV',
}

export enum QrCodePrefix {
  EXPORT_LOGIN_INFO = `EXPORT_LOGIN_INFO${SEPARATOR}`,
  EXPORT_VIRTUAL_ASSET = `EXPORT_VIRTUAL_ASSET${SEPARATOR}`,
  PROVE_HOLDERSHIP = `PROVE_HOLDERSHIP${SEPARATOR}`,
  PROVE_HOLDERSHIP_COMPRESSED = `PROVE_HOLDERSHIP_COMPRESSED${SEPARATOR}`,
  PROVE_OWNERSHIP = `PROVE_OWNERSHIP${SEPARATOR}`,
  VERIFY_HOLDERSHIP = `VERIFY_HOLDERSHIP${SEPARATOR}`,
}
