import type { LoginProps, NonLoginProps } from '@com/modals/TwoFAModal';
import type {
  Activity,
  AuthorizeNftResult,
  Balance,
  BalanceDetail,
  BalanceSummary,
  BindWalletArgs,
  CancelOrderArgs,
  ChangePasswordArgs,
  CheckoutBidArgs,
  CheckoutBuyArgs,
  CheckoutMintArgs,
  Collection,
  CollectionDetail,
  CollectionRelation,
  Config,
  CreateAuctionArgs,
  CreateSaleArgs,
  CurrencyData,
  FAQ,
  FlexibleMintSubmitRecord,
  FlexibleMintSubmitRecordDetail,
  GasReport,
  GetBidHistoryQueryArgs,
  GetCollectionHistoryQueryArgs,
  GetCollectionQueryArgs,
  GetEmailOtpArgs,
  GetFlexibleMintSubmitListQueryArgs,
  GetLaunchpadQueryArgs,
  GetNftHistoryQueryArgs,
  GetNftListQueryArgs,
  GetPhoneOtpArgs,
  GetSearchResultQueryArgs,
  GetUserCollectionQueryArgs,
  GetUserHistoryQueryArgs,
  GetUserNftQueryArgs,
  GkashBidArgs,
  GkashBuyArgs,
  GkashMintArgs,
  GkashPaymentDetail,
  HasUserEnabled2FAArgs,
  HomeBanner,
  KycStatus,
  Launchpad,
  LaunchpadDetail,
  LoginData,
  MarketplacePaginatedRes,
  MarketplaceSuccessRes,
  MintStatus,
  Nft,
  NftDetail,
  NftDetailWithoutAttr,
  NftEvent,
  Order,
  ProcessCheckoutResult,
  Receipt,
  RecoveryCodes,
  RegisterArgs,
  SSOLoginArgs,
  StripeBidArgs,
  StripeBuyArgs,
  StripeMintArgs,
  StripePaymentDetail,
  SubmitBankKycArgs,
  SubmitEmailKycArgs,
  SubmitFlexibleMintFormArgs,
  SubmitFlexibleMintResult,
  SubmitIdKycArgs,
  SubmitPhoneKycArgs,
  TakeHighestBidArgs,
  UpdateProfileArgs,
  User,
  VerifyEmailOtpArgs,
  VerifyPhoneOtpArgs,
  VerifyTreasuryAddressFn,
  WithdrawData,
  WithdrawDepositResult,
  WithdrawNftArgs,
} from '@shared';
import type { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { API_ERROR_MSG } from '@common';
import * as Sentry from '@sentry/react';
import { APIErrorStatus } from '@shared';
import { cache } from '@state/cache';
import { user } from '@state/user';
import { web3 } from '@state/web3';
import axios from 'axios';
import { Modal } from 'bootstrap';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createContainer } from 'unstated-next';

const { REACT_APP_API_BASE_URL, REACT_APP_DEFAULT_CHAINID } = process.env;

axios.defaults.baseURL = `${REACT_APP_API_BASE_URL}/api/v1`;
axios.defaults.transformResponse = (response: string) => {
  try {
    const parsedRes = JSON.parse(response);
    // External API may not follow .data practice, so return raw response if data == null
    return parsedRes.data != null ? parsedRes.data : parsedRes;
  } catch (error) {
    return response;
  }
};
const authInstance = axios.create();
let __userData: LoginData | null = null;

function useAPI() {
  const [defaultResInterceptor, setDefaultResInterceptor] = useState<number>(0);
  const [authResInterceptor, setAuthResInterceptor] = useState<number>(0);

  const { language, userData } = user.useContainer();
  const { updateTurnstileKey } = cache.useContainer();
  const { chainId: connectedChainId, disconnect } = web3.useContainer();
  const { i18n, t } = useTranslation();
  const { _paramsChainId } = useParams();
  const location = useLocation();

  // Auto fill-in all undefined chain_id and uuid
  const handleDefaultRequestConfig = (config: InternalAxiosRequestConfig) => {
    const autoAddData = (key: string, condition: unknown, value: unknown) => {
      const { data, params } = config;
      if (params && key in params && params[key] === condition)
        params[key] = value;
      if (data && key in data && data[key] === condition) data[key] = value;
    };

    // Params is the highest piority
    autoAddData(
      'chain_id',
      undefined,
      Number(_paramsChainId) ||
        connectedChainId ||
        Number(REACT_APP_DEFAULT_CHAINID)
    );
    return config;
  };

  // Auto show error alert box when API status code is 4xx
  const handleGlobalResponseError = (error: AxiosError) => {
    const { response } = error;
    const httpErrorCode = Number(response?.status) || 500;

    const httpErrorMsgList: Record<number, string> = {
      401: t('error.401'),
      // 404: t('error.404'), // Already use animation to handle it, no toast require
      422: t('error.422'),
      500: t('error.500'),
      504: t('error.504'),
    };

    const appErrorMsgList: Record<number, boolean | string> = {
      [APIErrorStatus.BALANCE_AMOUNT_MAX]: t('errorMsg.balance.belowMinimum'),
      [APIErrorStatus.BALANCE_AMOUNT_NOT_ENOUGH]: t(
        'errorMsg.balance.insufficient'
      ),
      [APIErrorStatus.COLLECTION_NOT_FOUND]: false,
      [APIErrorStatus.COUNTRY_UNAVAILABLE]: t(
        'toastMessage.countryUnavailable'
      ),
      [APIErrorStatus.EMAIL_ALREADY_EXISTS]: t(
        'toastMessage.emailAlreadyExists'
      ),
      [APIErrorStatus.EMAIL_ALREADY_SENT]: t(
        'toastMessage.verifyEmailAlreadySent'
      ),
      [APIErrorStatus.EMAIL_CODE_NOT_MATCH]: t('toastMessage.codeError'),
      [APIErrorStatus.EMAIL_LINK_INVALID]: t('toastMessage.emailLinkInvalid'),
      [APIErrorStatus.EMAIL_NOT_FOUND]: t('toastMessage.emailNotFound'),
      [APIErrorStatus.EMAIL_OTP_CODE_INVALID]: t(
        'toastMessage.emailOtpCodeInvalid'
      ),
      [APIErrorStatus.LAUNCHPAD_NOT_FOUND]: false,
      [APIErrorStatus.LAUNCHPAD_NOT_MEET_PHASE_RULE]: t(
        'toastMessage.mintTimeNotCorrect'
      ),
      [APIErrorStatus.LAUNCHPAD_PHASE_SOLD_OUT]: t(
        'toastMessage.launchpadPhaseSoldOut'
      ),
      [APIErrorStatus.LAUNCHPAD_PHASE_USER_MAX_MINT]: t(
        'alertMessage.reachUserQuota'
      ),
      [APIErrorStatus.ORDER_BID_PRICE_ERROR]: t('alertMessage.hasLatestPrice'),
      [APIErrorStatus.ORDER_NOT_ACTIVE]: t('toastMessage.orderNotActive'),
      [APIErrorStatus.ORDER_NOT_FOUND]: t('toastMessage.orderNotActive'),
      [APIErrorStatus.ORDER_PRICE_ERROR]: t('modal.checkMinBid'),
      [APIErrorStatus.ORDER_PRICE_NOT_MATCH]: t('modal.transactionFeeUpdated'),
      [APIErrorStatus.PARAMETER_ERROR]: t('toastMessage.ParameterError'),
      [APIErrorStatus.PARENT_COLLECTION_NFT_EXPIRED]: t(
        'toastMessage.parentExpired'
      ),
      [APIErrorStatus.PARENT_CONTRACT_NOT_OWNED]: t(
        'toastMessage.parentContractNotOwned'
      ),
      [APIErrorStatus.PERMISSION_DENY]: t('toastMessage.PermissionDeny'),
      [APIErrorStatus.PHONE_ALREADY_EXISTS]: t(
        'toastMessage.phoneAlreadyExists'
      ),
      [APIErrorStatus.PHONE_OTP_NOT_FOUND]: t('toastMessage.phoneOtpNotFound'),
      [APIErrorStatus.SSO_VERIFY_ACCESS_CODE_ERROR]: t(
        'toastMessage.SSOVerifyAccessCodeError'
      ),
      [APIErrorStatus.TREASURY_ADDRESS_NOT_EXIST]: t(
        'toastMessage.treasuryAddressNotExist'
      ),
      [APIErrorStatus.TURNSTILE_FAILED]: t('toastMessage.turnstileIssue'),
      [APIErrorStatus.TWOFA_BOUND]: t('toastMessage.2FABound'),
      [APIErrorStatus.TWOFA_OTP_INVALID]: t('toastMessage.2FAOtpInvalid'),
      [APIErrorStatus.TWOFA_RECOVERY_INVALID]: t(
        'toastMessage.2FARecoveryInvalid'
      ),
      [APIErrorStatus.TWOFA_SECRET_NOT_BIND]: t(
        'toastMessage.2FASecretNotBind'
      ),
      [APIErrorStatus.TWOFA_TIME_OUT]: t('toastMessage.2FATimeOut'),
      [APIErrorStatus.USER_LOGIN_INFO_ERROR]: t('toastMessage.loginError'),
      [APIErrorStatus.USER_NOT_ACCOUNT_KYC]: t(
        'toastMessage.needEmailKYCFirst'
      ),
      [APIErrorStatus.USER_NOT_BANK_KYC]: t('toastMessage.needBankKYCFirst'),
      [APIErrorStatus.USER_NOT_FOUND]: false,
      [APIErrorStatus.USER_NOT_FOUND_TIPS]: t('toastMessage.userNotFound'),
      [APIErrorStatus.USER_NOT_ID_KYC]: t('toastMessage.needIdKYCFirst'),
      [APIErrorStatus.WALLET_ALREADY_CONNECTED]: t(
        'toastMessage.walletAccountBound'
      ),
      [APIErrorStatus.WALLET_IS_NOT_TREASURY]: t(
        'toastMessage.needTransferToTreasury'
      ),
      [APIErrorStatus.WALLET_NOT_DISCONNECT]: t('toastMessage.notUnbindWallet'),
      [APIErrorStatus.WITHDRAW_NFT_PROCESSING]: t('modal.beingTakeAway'),
    };

    updateTurnstileKey();

    // Display HTTP level error message
    if (httpErrorCode > 400) {
      const httpErrorMsg = httpErrorMsgList[httpErrorCode];
      if (!toast.isActive(API_ERROR_MSG) && httpErrorMsg) {
        // only show one popup at the same time
        toast.error(httpErrorMsg, { toastId: API_ERROR_MSG });
      }
      if (httpErrorCode === 401) {
        /**
         * Since the redirect is async? So we have to add 500ms to make sure
         * the page is redirected. Otherwise, no '.modal.show' detected.
         */
        setTimeout(() => {
          document.querySelectorAll('.modal.show').forEach((modal) => {
            Modal.getInstance(modal)?.hide();
          });
        }, 500);
        disconnect('/login', { redirectTo: location.pathname });
      }
    }

    // Display application level error message
    if (httpErrorCode === 400) {
      const data: any = response?.data;
      const appErrorCode = Number(data?.code);
      const appErrorMsg = appErrorMsgList[appErrorCode];
      // Only show one popup at the same time
      if (!toast.isActive(API_ERROR_MSG) && appErrorMsg !== false) {
        // data.message only show in the development environment
        toast.error(
          appErrorMsg ||
            data?.message ||
            t('errorMsg.unexcepted', { code: appErrorCode }),
          {
            autoClose: appErrorMsg || data?.message ? 5000 : 10000,
            toastId: API_ERROR_MSG,
          }
        );
      }
    }

    return Promise.reject(error);
  };

  // Auto add bearer token for all private API
  const handleAuthRequestConfig = (config: InternalAxiosRequestConfig) => {
    const { headers } = config;
    if (headers && __userData?.token)
      headers.Authorization = `Bearer ${__userData.token}`;
    return config;
  };

  useEffect(() => {
    axios.interceptors.request.use(handleDefaultRequestConfig);
    authInstance.interceptors.request.use(handleAuthRequestConfig);
    authInstance.interceptors.request.use(handleDefaultRequestConfig);
  }, []);

  /**
   * FIXME: Since there will have delay if we use same implementaion like
   * i18n.language (eject + add) and this delay will cause to use old UUID + no API key
   * after logging in, so we create a variable out of React scope and update
   * instantly in order to slove this issue first.
   */
  useEffect(() => {
    __userData = userData;
  }, [userData]);

  useEffect(() => {
    if (i18n.language) {
      axios.interceptors.response.eject(defaultResInterceptor);
      authInstance.interceptors.response.eject(authResInterceptor);
      setDefaultResInterceptor(
        axios.interceptors.response.use(undefined, handleGlobalResponseError)
      );
      setAuthResInterceptor(
        authInstance.interceptors.response.use(
          undefined,
          handleGlobalResponseError
        )
      );
    }
  }, [i18n.language]);

  const privateAPI = {
    binding2FA: async ({ code, secret }: { code: string; secret: string }) => {
      const { data } = await authInstance.post<RecoveryCodes>(
        '/auth/2fa/bind',
        {
          code,
          secret,
        }
      );
      return data;
    },
    bindingWallet: async (args: BindWalletArgs) => {
      const { data } = await authInstance.post<User>(
        '/auth/connect_new_wallet',
        {
          address: args.address,
          nonce: args.nonce,
          signature: args.signature,
        }
      );
      return data;
    },
    cancelBankKyc: async () => {
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/bank-cancel'
      );
      return data;
    },
    cancelEmailKyc: async (code: string) => {
      const formData = new FormData();
      formData.append('code', code);
      await authInstance.post<KycStatus>('/auth/kyc/email-cancel', formData);
    },
    cancelIdKyc: async () => {
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/id-cancel'
      );
      return data;
    },
    cancelOrder: async (args: CancelOrderArgs) => {
      const { data } = await authInstance.post<Activity>(
        '/marketplace/order-cancel',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          order_id: args.orderId,
          token_id: args.tokenId,
        }
      );
      return data;
    },
    cancelPhoneKyc: async (code: string) => {
      const formData = new FormData();
      formData.append('code', code);
      await authInstance.post<KycStatus>('/auth/kyc/phone-cancel', formData);
    },
    changePassword: async (args: ChangePasswordArgs) => {
      const { data: isSuccess } = await authInstance.post<boolean>(
        '/auth/reset-password/reset',
        {
          k: args.oneTimeKey,
          password: args.password,
          password_confirmation: args.password,
          u: args.uuid,
        }
      );
      return isSuccess;
    },
    createAuction: async (args: CreateAuctionArgs) => {
      const { data } = await authInstance.post<Order>(
        '/marketplace/create-auction-order',
        {
          bid_increase_percentage: args.bidIncreasePercentage,
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          end_date: args.endTimestamp,
          minimum_bid: args.floorPrice,
          payment_method: args.paymentMethodId,
          quality: args.quantity, // typo issue
          seller_address: args.sellerAddress,
          token_id: args.tokenId,
        }
      );
      return data;
    },
    /*
      Market
    */
    createSale: async (args: CreateSaleArgs) => {
      const { data } = await authInstance.post<Order>(
        '/marketplace/create-sell-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          payment_method: args.paymentMethodId,
          quality: args.quantity, // typo issue
          seller_address: args.sellerAddress,
          selling_price: args.sellingPrice,
          token_id: args.tokenId,
        }
      );
      return data;
    },
    get2FAQRcode: async () => {
      const { data } = await authInstance.get('/auth/2fa/generate-secret');
      return data;
    },
    /*
      Balance
    */
    getBalance: async () => {
      const { data } =
        await authInstance.post<BalanceSummary[]>('/balance/data');
      return data;
    },
    getBalanceDetail: async (invoiceId: string) => {
      const { data } = await authInstance.post<BalanceDetail>(
        '/balance/query',
        {
          no: invoiceId,
        }
      );
      return data;
    },
    getBalanceList: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await authInstance.post<
        MarketplacePaginatedRes<Balance[]>
      >('/balance/list', {
        cursor: pageParam,
      });
      return data;
    },
    getFlexibleMintSubmitDetail: async (recordId: string) => {
      const { data } = await authInstance.post<FlexibleMintSubmitRecordDetail>(
        '/launchpad/phase/record',
        {
          submit_uuid: recordId,
        }
      );
      return data;
    },
    getFlexibleMintSubmitList: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetFlexibleMintSubmitListQueryArgs;
    }) => {
      const { launchpadId, phaseId, status } = queryKey[1];
      const { data } = await authInstance.post<
        MarketplacePaginatedRes<FlexibleMintSubmitRecord[]>
      >('/launchpad/phase/record-list', {
        cursor: pageParam,
        phase_uuid: phaseId,
        status,
        uuid: launchpadId,
      });
      return data;
    },
    /*
      Gas
    */
    getGasReport: async (orderId: number, walletAddress: string) => {
      const { data } = await authInstance.post<GasReport>(
        '/marketplace/check-order',
        {
          order_id: orderId,
          wallet_address: walletAddress,
        }
      );
      return data;
    },
    getKycStatus: async () => {
      const { data } = await authInstance.get<KycStatus>('/auth/kyc/status');
      return data;
    },
    getLaunchpadDetails: async (uuid: string, chainId?: number) => {
      // Use auth instance for checking this user minted amount > user quota or not
      const { data } = await authInstance.post<LaunchpadDetail>(
        '/launchpad/detail',
        {
          chain_id: chainId,
          uuid, // Accept UUID or custom URL
        }
      );
      return data;
    },
    /*
      Others
    */
    getLoginStatus: async () => {
      const { data: isLogin } = await authInstance.get<true>('/auth/status');
      return isLogin;
    },
    getMintStatus: async (phaseId: string, address: string) => {
      const { data } = await authInstance.post<MintStatus>('/launchpad/check', {
        phase_uuid: phaseId,
        wallet_address: address?.toLowerCase(),
      });
      return data;
    },
    getNftEvents: async (chainId: number, address: string, tokenId: number) => {
      const { data } = await authInstance.post<NftEvent[]>(
        '/event/nft/events',
        {
          address: address?.toLowerCase(),
          chain_id: chainId,
          token_id: tokenId,
        }
      );
      return data;
    },
    getNftQRCode: async (chainId: number, address: string, tokenId: number) => {
      const { data } = await authInstance.post<string>(
        '/event/nft/owner-qrcode',
        {
          address: address?.toLowerCase(),
          chain_id: chainId,
          token_id: tokenId,
        }
      );
      return data;
    },
    /*
      Profile
    */
    getProfile: async (uuid: string, chainId?: number) => {
      // Backend will check is profile owner or not to return different value
      const { data } = await authInstance.post<User>('/profile/query', {
        chain_id: chainId,
        uuid,
      });
      return data;
    },
    getReceiptDetail: async (invoiceId: string) => {
      const { data } = await authInstance.post<Receipt>('/receipt/query', {
        no: invoiceId,
      });
      return data;
    },
    /*
      Receipt
    */
    getReceiptList: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await authInstance.post<
        MarketplacePaginatedRes<Receipt[]>
      >('/receipt/list', {
        cursor: pageParam,
      });
      return data;
    },
    getWithdrawNftCode: async (args: WithdrawData) => {
      const { data: isSuccess } = await authInstance.post<boolean>(
        '/profile/withdraw-nft/get-code',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          // Here not changing the key for backward compatibility, should support email also
          wallet_address: args.receiver?.toLowerCase(),
        }
      );
      return isSuccess;
    },
    // Same route with isUsernameValid, but this api will use authInstance (filter current username)
    isProfileNameValid: async (name: string) => {
      const { data: isDuplicate } = await authInstance.post<boolean>(
        '/profile/check-user',
        {
          name,
        }
      );
      return isDuplicate;
    },
    logout: async () => {
      try {
        await authInstance.post('/auth/logout');
      } catch (error) {
        // FIXME: Have to use try catch to ensure not blocking disconnect process
        Sentry.captureException(error);
      }
    },
    processCheckoutBid: async (args: CheckoutBidArgs) => {
      const { data } = await authInstance.post<ProcessCheckoutResult>(
        '/marketplace/bid-order',
        {
          bidder_address: args.bidderAddress,
          card_token: args.cardToken,
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          price: args.bidPrice,
          token_id: args.tokenId,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processCheckoutBuy: async (args: CheckoutBuyArgs) => {
      const { data } = await authInstance.post<ProcessCheckoutResult>(
        '/marketplace/buy-order',
        {
          buyer_address: args.buyerAddress,
          card_token: args.cardToken,
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    /*
      Checkout Payment
    */
    processCheckoutMint: async (args: CheckoutMintArgs) => {
      if (args.submitId) {
        const { data } = await authInstance.post<ProcessCheckoutResult>(
          '/launchpad/phase/mint_nft',
          {
            amount: args.amount,
            card_token: args.cardToken,
            submit_uuid: args.submitId,
            total_price: args.totalPrice,
            wallet_address: args.walletAddress,
          }
        );
        return data;
      }

      const { data } = await authInstance.post<ProcessCheckoutResult | Receipt>(
        '/launchpad/mint_nft',
        {
          amount: args.amount,
          card_token: args.cardToken,
          phase_uuid: args.phase,
          total_price: args.totalPrice,
          wallet_address: args.walletAddress,
        }
      );
      return data;
    },
    processGkashBid: async (args: GkashBidArgs) => {
      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/marketplace/bid-order',
        {
          bidder_address: args.bidderAddress,
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          price: args.bidPrice,
          token_id: args.tokenId,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processGkashBuy: async (args: GkashBuyArgs) => {
      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/marketplace/buy-order',
        {
          buyer_address: args.buyerAddress,
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    /*
      Gkash Payment
    */
    processGkashMint: async (args: GkashMintArgs) => {
      if (args.submitId) {
        const { data } = await authInstance.post<GkashPaymentDetail>(
          '/launchpad/phase/mint_nft',
          {
            amount: args.amount,
            submit_uuid: args.submitId,
            total_price: args.totalPrice,
            wallet_address: args.walletAddress,
          }
        );
        return data;
      }

      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/launchpad/mint_nft',
        {
          amount: args.amount,
          phase_uuid: args.phase,
          total_price: args.totalPrice,
          wallet_address: args.walletAddress,
        }
      );
      return data;
    },
    processStripeBid: async (args: StripeBidArgs) => {
      const { data } = await authInstance.post<StripePaymentDetail>(
        '/marketplace/bid-order',
        {
          bidder_address: args.bidderAddress,
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          price: args.bidPrice,
          token_id: args.tokenId,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processStripeBuy: async (args: StripeBuyArgs) => {
      const { data } = await authInstance.post<StripePaymentDetail>(
        '/marketplace/buy-order',
        {
          buyer_address: args.buyerAddress,
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    /*
      Stripe Payment
    */
    processStripeMint: async (args: StripeMintArgs) => {
      if (args.submitId) {
        const { data } = await authInstance.post<StripePaymentDetail>(
          '/launchpad/phase/mint_nft',
          {
            amount: args.amount,
            submit_uuid: args.submitId,
            total_price: args.totalPrice,
            wallet_address: args.walletAddress,
          }
        );
        return data;
      }

      const { data } = await authInstance.post<StripePaymentDetail>(
        '/launchpad/mint_nft',
        {
          amount: args.amount,
          phase_uuid: args.phase,
          total_price: args.totalPrice,
          wallet_address: args.walletAddress,
        }
      );
      return data;
    },
    sendResetPasswordEmail: async (email: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/reset-password/send',
        { email }
      );
      return isSuccess;
    },
    sendVerifyAccountEmail: async (email: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/email/verify/resend',
        { email }
      );
      return isSuccess;
    },
    setNftAsAvatar: async (
      chainId: number,
      collectionAddress: string,
      tokenId: number
    ) => {
      const { data } = await authInstance.post<User>(
        '/profile/update-avatar-nft',
        {
          chain_id: chainId,
          collection_address: collectionAddress,
          token_id: tokenId,
        }
      );
      return data;
    },
    submit2FARecovery: async ({ recovery_code }: RecoveryCodes) => {
      const { data } = await authInstance.post('/auth/2fa/recovery', {
        recovery_code,
      });
      return data;
    },
    submitBankKyc: async (args: SubmitBankKycArgs) => {
      const formData = new FormData();
      formData.append('country', args.country);
      formData.append('bank_name', args.bankName);
      formData.append('bank_account_no', args.bankAccountNumber);
      formData.append('bank_document_image', args.cardImage);
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/bank-submit',
        formData
      );
      return data;
    },
    submitEmailKyc: async (args: SubmitEmailKycArgs) => {
      const formData = new FormData();
      formData.append('email', args.email);
      formData.append('code', args.code);
      await authInstance.post<KycStatus>('/auth/kyc/email-submit', formData);
    },
    /*
      Flexible Mint
    */
    submitFlexibleMintForm: async (args: SubmitFlexibleMintFormArgs) => {
      const formData = new FormData();

      formData.append('wallet_address', args.walletAddress);
      formData.append('phase_uuid', args.phaseId);

      for (const [type, data] of Object.entries(args.metadata)) {
        for (const [key, value] of Object.entries(data)) {
          formData.append(
            `${type}[${key}]`,
            value instanceof FileList ? value[0] : String(value)
          );
        }
      }

      const { data } = await authInstance.post<SubmitFlexibleMintResult>(
        '/launchpad/phase/submit',
        formData
      );
      return data;
    },
    submitIdKyc: async (args: SubmitIdKycArgs) => {
      const formData = new FormData();
      formData.append('card_image', args.cardImage);
      formData.append('country', args.country);
      formData.append('full_name', args.fullName);
      formData.append('card_no', args.cardNumber);
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/id-submit',
        formData
      );
      return data;
    },
    submitPhoneKyc: async (args: SubmitPhoneKycArgs) => {
      const formData = new FormData();
      formData.append('phone', args.phone);
      formData.append('code', args.code);
      formData.append('phone_country', args.phone_country);
      await authInstance.post<KycStatus>('/auth/kyc/phone-submit', formData);
    },
    takeHighestBid: async (args: TakeHighestBidArgs) => {
      const { data } = await authInstance.post<Activity>(
        '/marketplace/accept-bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          order_id: args.orderId,
          token_id: args.tokenId,
        }
      );
      return data;
    },
    unbinding2FA: async ({ code }: { code: string }) => {
      const { data } = await authInstance.post<MarketplaceSuccessRes<boolean>>(
        '/auth/2fa/cancel-bind',
        {
          code,
        }
      );
      return data;
    },
    unbindingWallet: async (args: BindWalletArgs) => {
      const { data } = await authInstance.post<User>(
        '/auth/disconnect_wallet',
        {
          address: args.address,
          nonce: args.nonce,
          signature: args.signature,
        }
      );
      return data;
    },
    updateAvatar: async (avatar?: File) => {
      const formData = new FormData();
      if (avatar) formData.append('avatar', avatar);
      const { data } = await authInstance.post<Exclude<User, 'wallet'>>(
        '/profile/update-avatar',
        formData
      );
      return data;
    },
    updateProfile: async (args: UpdateProfileArgs) => {
      const { data } = await authInstance.post<User>('/profile/update', {
        bio: args.bio,
        language: args.language,
        name: args.name,
        personal_site: args.personalSite,
        social_link_discord: args.discordLink,
        social_link_facebook: args.facebookLink,
        social_link_twitter: args.twitterLink,
      });
      return data;
    },
    verify2FACode: async (
      data: { code: string } & (LoginProps | NonLoginProps)
    ) => {
      const { data: isVerify } = await authInstance.post<boolean | LoginData>(
        '/auth/2fa/verify',
        data
      );
      return isVerify;
    },
    verifyAccount: async (uuid: string, oneTimeKey: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/email/verify',
        {
          k: oneTimeKey,
          u: uuid,
        }
      );
      return isSuccess;
    },
    // 不建议使用箭头函数做函数重载
    verifyTreasuryAddress: (async (args) => {
      const { data } = await authInstance.post(
        '/profile/deposit-nft/check',
        args
      );

      return data;
    }) as VerifyTreasuryAddressFn,
    withdrawDeposit: async (amount: number, currency: string) => {
      const { data } = await authInstance.post<WithdrawDepositResult>(
        '/balance/request',
        {
          amount,
          currency,
        }
      );
      return data;
    },
    withdrawNft: async (args: WithdrawNftArgs) => {
      const { data: isSuccess } = await authInstance.post<boolean>(
        '/profile/withdraw-nft/submit',
        {
          chain_id: args.chainId,
          code: args.code,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          wallet_address: args.receiver?.toLowerCase(),
        }
      );
      return isSuccess;
    },
  };

  const publicAPI = {
    emailLogin: async (
      email: string,
      password: string,
      turnstileResponse: string
    ) => {
      const { data } = await axios.post<LoginData>('/auth/login', {
        cf_turnstile_response: turnstileResponse,
        email,
        password,
      });
      return data;
    },
    emailRegister: async (args: RegisterArgs) => {
      const { data } = await axios.post<LoginData>('/auth/register', {
        cf_turnstile_response: args.turnstileResponse,
        email: args.email,
        language,
        name: args.name,
        password: args.password,
        password_confirmation: args.confirmPassword,
        type: args.type,
      });
      return data;
    },
    /*
      Index
    */
    getBannerList: async () => {
      const { data } = await axios.get<HomeBanner[]>('/system/banner');
      return data;
    },
    getBidHistory: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetBidHistoryQueryArgs;
    }) => {
      const { orderId } = queryKey[1];
      const { data } = await axios.get<MarketplacePaginatedRes<Activity[]>>(
        '/marketplace/bid-record',
        {
          params: {
            cursor: pageParam,
            order_id: orderId,
          },
        }
      );
      return data;
    },
    getCollectionActivity: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetCollectionHistoryQueryArgs;
    }) => {
      const { address, chainId } = queryKey[1];
      const { data } = await axios.post<MarketplacePaginatedRes<Activity[]>>(
        '/collection/activity',
        {
          address: address?.toLowerCase(),
          chain_id: chainId,
          cursor: pageParam,
        }
      );
      return data;
    },
    getCollectionDetails: async (
      collectionAddress: string,
      chainId?: number
    ) => {
      const { data } = await axios.post<CollectionDetail>(
        '/collection/detail',
        {
          address: collectionAddress?.toLowerCase(), // Accept address or custom URL
          chain_id: chainId,
        }
      );
      return data;
    },
    /*
      Collection
    */
    getCollectionList: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetCollectionQueryArgs;
    }) => {
      const { category, chainId } = queryKey[1];
      const { data } = await axios.get<MarketplacePaginatedRes<Collection[]>>(
        '/collection',
        {
          params: {
            category,
            chain_id: chainId,
            cursor: pageParam,
          },
        }
      );
      return data;
    },
    getCollectionRelation: async (
      chainId: number,
      collectionAddress: string
    ) => {
      const { data } = await axios.post<CollectionRelation>(
        '/collection/parent-record',
        {
          address: collectionAddress?.toLowerCase(),
          chain_id: chainId,
        }
      );
      return data;
    },
    getConfig: async (chainId?: number) => {
      // chain_id will affect currency
      const { data } = await axios.get<Config>('/system/config', {
        params: { chain_id: chainId },
      });
      return data;
    },
    /*
      System
    */
    getCurrencyList: async (chainId?: number, isActive = true) => {
      const { data } = await axios.get<CurrencyData[]>('/currency', {
        params: {
          chain_id: chainId,
          is_active: isActive,
        },
      });
      return data;
    },
    getEmailOtp: async ({
      action = 'login_or_register',
      email,
      turnstileResponse,
      type,
    }: GetEmailOtpArgs) => {
      const { data: isSuccess } = await axios.post<boolean>(
        '/auth/email-otp/send',
        {
          action,
          cf_turnstile_response: turnstileResponse,
          email,
          type,
        }
      );
      return isSuccess;
    },
    getFAQ: async () => {
      const { data } = await axios.get<FAQ[]>('/system/faq');
      return data;
    },
    /*
      Launchpad
    */
    getLaunchpadList: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetLaunchpadQueryArgs;
    }) => {
      const { category, chainId } = queryKey[1];
      const { data } = await axios.get<MarketplacePaginatedRes<Launchpad[]>>(
        '/launchpad',
        {
          params: {
            category,
            chain_id: chainId,
            cursor: pageParam,
          },
        }
      );
      return data;
    },
    getLiveOrder: async (chainId?: number) => {
      const { data } = await axios.get<NftDetailWithoutAttr[]>(
        '/marketplace/live-order',
        {
          params: { chain_id: chainId },
        }
      );
      return data;
    },
    getNftAuthorization: async ({
      chainId,
      collectionAddress,
      expiresAt,
      tokenId,
      walletAddress,
    }: {
      chainId: number;
      collectionAddress: string;
      expiresAt?: number;
      tokenId: number;
      walletAddress: string;
    }) => {
      const { data } = await authInstance.post<AuthorizeNftResult>(
        '/collection/nft/authorization',
        {
          chain_id: chainId,
          collection_address: collectionAddress,
          expires_at: expiresAt,
          token_id: tokenId,
          wallet_address: walletAddress,
        }
      );
      return data;
    },
    getNftDetails: async (
      tokenId: number,
      collectionAddress: string,
      chainId?: number
    ) => {
      const { data } = await authInstance.post<NftDetail>(
        '/marketplace/order-detail',
        {
          chain_id: chainId,
          collection_address: collectionAddress?.toLowerCase(),
          token_id: tokenId,
        }
      );
      return data;
    },
    getNftHistory: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetNftHistoryQueryArgs;
    }) => {
      const { chainId, collectionAddress, tokenId } = queryKey[1];
      const { data } = await authInstance.post<
        MarketplacePaginatedRes<Activity[]>
      >('/collection/nft/activity', {
        chain_id: chainId,
        collection_address: collectionAddress?.toLowerCase(),
        cursor: pageParam,
        token_id: tokenId,
      });
      return data;
    },
    getNftList: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetNftListQueryArgs;
    }) => {
      const { attributes, chainId, collectionAddress, filter, sort } =
        queryKey[1];

      const search = attributes
        ?.map(
          (item) =>
            `search[${encodeURIComponent(
              item.category
            )}][]=${encodeURIComponent(item.value)}`
        )
        .join('&');

      const { data } = await axios.get<Nft[]>(
        `/marketplace/order-list${search ? `?${search}` : ''}`,
        {
          params: {
            chain_id: chainId,
            collection_address: collectionAddress?.toLowerCase(),
            cursor: pageParam,
            filter,
            sort,
          },
        }
      );
      return data;
    },
    getPhoneOtp: async ({
      action = 'login_or_register',
      cca2,
      e164Phone,
      turnstileResponse,
      type,
    }: GetPhoneOtpArgs) => {
      const { data: isSuccess } = await axios.post<boolean>(
        '/auth/phone-otp/send',
        {
          action,
          cf_turnstile_response: turnstileResponse,
          phone: e164Phone,
          phone_country: cca2,
          type,
        }
      );
      return isSuccess;
    },
    getPopularCollection: async (chainId?: number) => {
      const { data } = await axios.get<MarketplacePaginatedRes<Collection[]>>(
        '/collection',
        {
          params: { chain_id: chainId },
        }
      );
      return data.data?.slice(0, 3);
    },
    getSearchResult: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetSearchResultQueryArgs;
    }) => {
      const { category, chainId, keyword } = queryKey[1];
      const { data } = await axios.post<
        MarketplacePaginatedRes<(Collection | Nft | User)[]>
      >(`/search/${category}`, {
        chain_id: chainId,
        cursor: pageParam,
        q: keyword,
      });
      return data;
    },
    getUserActivity: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetUserHistoryQueryArgs;
    }) => {
      const { chainId, uuid } = queryKey[1];
      const { data } = await axios.post<MarketplacePaginatedRes<Activity[]>>(
        '/profile/activity',
        {
          chain_id: chainId,
          cursor: pageParam,
          uuid,
        }
      );
      return data;
    },
    /*
      User
    */
    getUserCollection: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetUserCollectionQueryArgs;
    }) => {
      const { category, chainId, uuid } = queryKey[1];
      const { data } = await axios.post<MarketplacePaginatedRes<Collection[]>>(
        '/profile/user_collection',
        {
          category,
          chain_id: chainId,
          cursor: pageParam,
          uuid,
        }
      );
      return data;
    },
    getUserNft: async ({
      pageParam,
      queryKey,
    }: {
      pageParam?: string;
      queryKey: GetUserNftQueryArgs;
    }) => {
      const { category, chainId, itemsPerPage, search, sort, type, uuid } =
        queryKey[1];
      const { data } = await authInstance.post<
        MarketplacePaginatedRes<NftDetailWithoutAttr[]>
      >('/profile/user_nft', {
        category,
        chain_id: chainId,
        cursor: pageParam,
        limit: itemsPerPage,
        search,
        sort,
        type,
        uuid,
      });
      return data;
    },
    /*
      Auth
    */
    hasUserEnabled2FA: async ({ account, type }: HasUserEnabled2FAArgs) => {
      const { data: hasEnabled2FA } = await axios.post<boolean>(
        '/auth/2fa/find',
        {
          account,
          type,
        }
      );
      return hasEnabled2FA;
    },
    isUsernameValid: async (name: string) => {
      const { data: isDuplicate } = await axios.post<boolean>(
        '/auth/check-user',
        { name }
      );
      return isDuplicate;
    },
    setNftVisible: async (
      chainId: number,
      collectionAddress: string,
      tokenId: number,
      isVisible: boolean
    ) => {
      const { data: isPublic } = await authInstance.post<boolean>(
        '/collection/nft/set-visible',
        {
          chain_id: chainId,
          collection_address: collectionAddress,
          is_visible: isVisible,
          token_id: tokenId,
        }
      );
      return isPublic;
    },
    ssoLogin: async ({
      authCode,
      codeVerifier,
      redirectUri,
      type,
    }: SSOLoginArgs) => {
      const res = await axios.post<LoginData>('/auth/sso', {
        code: authCode,
        code_verifier: codeVerifier,
        redirect_uri: redirectUri,
        type,
      });
      return res.data;
    },
    verifyEmailOtp: async ({ email, otpCode }: VerifyEmailOtpArgs) => {
      const { data } = await axios.post<LoginData>('/auth/email-otp/verify', {
        code: otpCode,
        email,
        language,
      });
      return data;
    },
    verifyPhoneOtp: async ({
      cca2,
      e164Phone,
      otpCode,
    }: VerifyPhoneOtpArgs) => {
      const { data } = await axios.post<LoginData>('/auth/phone-otp/verify', {
        code: otpCode,
        language,
        phone: e164Phone,
        phone_country: cca2,
      });
      return data;
    },
  };

  // Prevent duplicate key on private and public API (key duplicate will overriding and hard to debug)
  const combine = <
    T extends object,
    U extends { [K in keyof T]?: undefined } & object,
  >(
    obj1: T,
    obj2: U
  ) => {
    return {
      ...obj1,
      ...obj2,
    };
  };

  return { ...combine(privateAPI, publicAPI) };
}

export const api = createContainer(useAPI);
